import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  ApiUrl = environment.baseUrl

  constructor(
    public http: HttpClient
  ) { }


  GetLog(currentShareCode) {
    return this.http.get(this.ApiUrl + 'ships-log/get-log-for-share/' + currentShareCode, {})
  }
  GetAllLog(currentShareCode) {
    return this.http.get(this.ApiUrl + 'ships-log/get-all-log-for-share/' + currentShareCode, {})
  }
  

  Login(data) {
    return this.http.post(this.ApiUrl + 'user/signin', data)
  }

  GetAllUsers() {
    return this.http.get(this.ApiUrl + 'user/getall')
  }

  Approve_User(id, data) {
    return this.http.post(this.ApiUrl + 'user/change-status-isapprove/' + id, data)
  }

  Disable_User_Account(id, data) {
    return this.http.post(this.ApiUrl + 'user/isAccountDisabled-true-false/' + id, data)
  }

  Is_Admin(id, data) {
    return this.http.post(this.ApiUrl + 'user/isAdmin-true-false/' + id, data)
  }

  DeleteUser(id) {
    return this.http.post(this.ApiUrl + 'user/delete/' + id, {})
  }

  UpdateRoleId(userId, data) {
    return this.http.post(this.ApiUrl + 'user/update-roleId/' + userId, data)
  }

  updateInviteStatus(userId, data) {
    return this.http.post(this.ApiUrl + 'user/change-invite-status/' + userId, data)
  }

  updateInviteCode(userId, data) {
    return this.http.post(this.ApiUrl + 'user/change-invite-code/' + userId, data)
  }
}
