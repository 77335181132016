import { AppService } from '../app.service';
import { Component,TemplateRef, OnInit } from '@angular/core'
import { UserService } from '../services/user.service';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Map, tileLayer, marker, icon } from "leaflet";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';

declare var L: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  @ViewChild('viewLog') viewLog: TemplateRef<any>;
  constructor(
    private appService: AppService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private modalService: NgbModal,) {
    this.appService.pageTitle = 'Home';
    console.log(this.activeRoute.snapshot.params)
  }
  
  shareCode="";
  url = environment.baseUrl;
  urlIconsUsers = [];
  userDetails;
  whattype="";
  shipLogs = []
  shipLogEntries = 0
allLogs=[];
  layerGroup:any = {}
  currentIndex=0;
  totalEntries=0;
  hideNext=false;
  hidePrevious=false;
  ngOnInit() {
    this.layerGroup['shipsLog'] = [];
    this.activeRoute.params.subscribe((activeRoute: any) => {
     
      this.shareCode = activeRoute.shipLog
      this.whattype = activeRoute.whattype
      // this.searchLatitude = parseFloat(activeRoute.searchLatitude);
      // this.searchLongitude = parseFloat(activeRoute.searchLongitude);
        // ___---___  console.log(this.searchLatitude)
      });
    if(this.whattype == "all") {
      
      this.userService.GetAllLog(this.shareCode)
      .subscribe((resp: any) => {
        console.log(resp)
        this.shipLogs = resp.shipsLog
        this.userDetails = resp.shipsLog[0].user;
        console.log(this.userDetails);
        this.showMap();
      });
    }else {
      this.userService.GetLog(this.shareCode)
      .subscribe((resp: any) => {
        console.log(resp)
        this.shipLogs = resp.shipsLog
        this.userDetails = resp.shipsLog[0].user;
        console.log(this.userDetails);
        this.showMap();
      });
    }
    
  }
  latitude = 55.3781;
  longitude = 3.4360;
  opacity = 1
  zoom = 3


  map: Map;
  markers: any;
  zoomMap(lng,lat) {
    this.map.setView([
      lat,lng
    ], 13);
    this.modalService.dismissAll()
  }
  showMap() {
    this.map = new Map('myMap').setView([
      this.latitude, this.longitude
    ], 5);
    
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a>',
      zoomControl: false,
          maxZoom: 18,
    }).addTo(this.map);
    L.control.zoom({
      position: 'topright',
    })
    if (this.shipLogs.length !== 0) {
      var tooky;

      let itemsProcessed=0;
      this.shipLogs.forEach(element => {
        element.icon = "log"+element.colour+"Start.svg"
        itemsProcessed++;
        this.allLogs.push(element);
        element.trackings.forEach(trackingelement => {
          itemsProcessed++;
          if(trackingelement.isLastEntry === true) {
            trackingelement.icon = "log"+trackingelement.colour+"Finish.svg";
          } else {
            trackingelement.icon = "log"+trackingelement.colour+"Point.svg";
          }
          this.allLogs.push(trackingelement);
        });
        this.allLogs.forEach((element,index) => {
          
          this.addMarker(element.lat, element.lng, element,element.icon,index)
        });
this.totalEntries = this.allLogs.length;
        // if(itemsProcessed === this.shipLogs.length) {
          var layers = []
          tooky = L.featureGroup(this.layerGroup.shipsLog).addTo(this.map);
          var overlays = {};
          // overlays["Shipmates"] = layers["users"];
          // L.control.layers(null, overlays).addTo(this.map);
          this.map.fitBounds(tooky.getBounds().pad(0.5))
        // }
      });
     
    }
    // $('#myMap').on('click', '.trigger', function () {
    //   console.log('clicked inside bind pop')
    // });
  }
  addMarker(lat, lng, element,myicon,index) {
    // console.log(element)
    const customMarkerIcon = icon({
      iconUrl: this.url +"imgs/"+ myicon,
      iconSize: [31.25,36.75],
      popupAnchor: [0, -20]
    });
    this.markers = L.marker([lat, lng], {
      icon: customMarkerIcon,
      item: element,
      theIndex:index
    });
    let templateContent = '';
    
    this.markers
      .on('click', (val) => {
        this.currentIndex = val.target.options.theIndex;
console.log(this.currentIndex);
        if(this.currentIndex==0) {
          this.hidePrevious=true
        } else {
          this.hidePrevious=false
        }
        console.log(val.target.options)
        
        // }).bindPopup(element.name)
        this.openModal(this.viewLog,val.target.options.item)
      })
      // .bindPopup(templateContent,{minWidth:"300"});
      this.layerGroup['shipsLog'].push(this.markers);
  }
  info;
  nextLog() {


    this.info = this.allLogs[this.currentIndex+1];
    this.currentIndex++;
    if(this.allLogs.length-1 == this.currentIndex) {
      this.hideNext=true
    } else {
      this.hideNext=false
    }
    if(this.currentIndex==0) {
      this.hidePrevious=true
    } else {
      this.hidePrevious=false
    }
    this.map.setView([
      this.allLogs[this.currentIndex+1].lat,this.allLogs[this.currentIndex+1].lng
    ], 9);
  }
  previousLog() {


    this.info = this.allLogs[this.currentIndex-1];
    this.currentIndex--;
    if(this.allLogs.length-1 == this.currentIndex) {
      this.hideNext=true
    } else {
      this.hideNext=false
    }
    if(this.currentIndex==0) {
      this.hidePrevious=true
    } else {
      this.hidePrevious=false
    }
    
  }
  openModal(viewLog : TemplateRef<any>,item) {
    this.info = item;
    console.log(this.info)
  
    this.modalService.open(viewLog, {
      centered: true,
      size: 'sm',
      backdrop: 'static',
    });
  }
  ontrackClick(cal) {
    // console.log(cal)
  }
  removeAllMarkers() {
    this.map.remove();
  }
  ngOnDestroy() {
    this.removeAllMarkers()
  }
  // dragger() {
  //   drag = new OpenLayers.Control.DragFeature(vectors, {autoActivate: true});
  //   map.addControl(drag);
  // }
  clickedOnMap(ev) {
    console.log(ev)

  }
}
