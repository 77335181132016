
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// *******************************************************************************
// Layouts

import { Layout1Component } from './layout/layout-1/layout-1.component';

// *******************************************************************************
// Pages

import { HomeComponent } from './home/home.component';
// import { AuthGuard } from './guard/auth.guard';
// import { forwardGuard } from './guard/forward.guard';
// import { Layout2Component } from './layout/layout-2/layout-2.component';

// *******************************************************************************
// Routes

const routes: Routes = [

  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // {
  //   path: 'home/:shipLog', 
  //   component: HomeComponent,
  //   pathMatch: 'full'
    
  // }
  { path: ':shipLog', component: HomeComponent, pathMatch: 'full' },
  { path: ':shipLog/:whattype', component: HomeComponent, pathMatch: 'full' },
  { path: '', component: HomeComponent, pathMatch: 'full' },
  // {
  //   path: 'home/:shipLog', 
  //   component: HomeComponent,
  //   pathMatch: 'full'
    
  // }

  



];

// *******************************************************************************
//

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
